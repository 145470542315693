<template>
  <div
    v-if="theme"
    key="theme"
    class="p-4 rounded-xl"
    :style="{ backgroundColor: theme ? theme.Color : undefined }"
  >
    <NuxtLink
      :to="{
        name: 'theme',
        params: { theme: theme.UrlKey },
      }"
    >
      <div class="flex relative z-10 mb-1">
        <img
          v-if="smallImage"
          class="w-15 h-15 rounded-md shrink-0"
          :src="theme.Image"
        />
        <div>
          <div :class="{ 'ml-6': smallImage }" class="label-m">
            {{ lastWeek ? 'Sidste uges tema' : 'Ugens tema' }}
          </div>
          <div
            :class="{ 'ml-6': smallImage }"
            class="list-title-s !font-normal mb-5"
          >
            {{ theme.Title }}
          </div>
          <img
            v-if="!smallImage"
            class="w-full rounded-md shrink-0 mb-5"
            :src="theme.Image"
          />
        </div>
      </div>
      <div
        v-if="showDescription"
        class="w-auto text-lg mb-5"
        v-html="theme.Text"
      />
    </NuxtLink>
    <ul class="list-disc list-outside list-title-xxxs">
      <template v-for="(item, index) in theme.Articles" :key="index">
        <li class="ml-4">
          <NuxtLink
            :to="{
              name: indexStore.currentPaperSlug ? 'paper_article' : 'article',
              params: indexStore.currentPaperSlug
                ? { paper: indexStore.currentPaperSlug, id: item.UrlKey }
                : { id: item.UrlKey },
            }"
          >
            {{ item.HeadLine || item.Headline }}
          </NuxtLink>
        </li>
        <CommonDivider
          v-if="index !== theme.Articles.length - 1"
          :key="'divider' + index"
        />
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { WeeklyTheme } from '~/typesManual/content_api/weekly_theme'

const props = defineProps<{
  lastWeek?: boolean
  fixedTheme?: WeeklyTheme
  smallImage?: boolean
  showDescription?: boolean
}>()

const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()

const { data: theme } = await useAsyncData(
  `theme-${props.lastWeek}`,
  async () => {
    if (props.fixedTheme) {
      return props.fixedTheme
    }

    const themes = await nuxtApp.$api.content.mmThemes({
      count: 1,
      offset: props.lastWeek ? 1 : 0,
    })

    if (themes && themes[0]) {
      return themes[0]
    }
  }
)
</script>
